<template>
  <div class='my-4'>
    <label class='text-sm font-semibold block mb-2'>Weekly Configurations</label>
    <label class='p-2 text-left text-gray-500 font-light cursor-pointer hover:bg-gray-200'>
      <input type='checkbox'
        class='form-checkbox mr-1'
        name='ID'
        v-model='show_weekends'>
        <span class='text-xs whitespace-no-wrap'>Show Weekends</span>
    </label>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'DashboardViewWeeklyConfigurations',
  components: {
  },
  computed: {
    ...mapFields('dashboardViews', [
      'currentDashboardView.weekly_configurations.show_weekends',
    ]),
  },
}
</script>
